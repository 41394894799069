<template>
  <!-- 多选 -->
  <div class="checkWrap" :id="'question' + list.seq">
    <div class="detailTitle clearfix">
      <span class="detailMate">{{ list.seq }}</span>
      <div class="detailTitlefr">
        <span class="detailCate">多选题</span>
        <span class="detailScore"
          >[{{ list.required == 0 ? "选填" : "必填" }}]</span
        >
        {{ list.name }}<img :src="list.img" v-if="list.img" @click="checkImg(list.img)"/>
      </div>
    </div>
    <div class="itemDetail">
      <div class="detailOption">
        <el-checkbox-group v-model="list.radio" disabled>
          <el-checkbox
            :label="item.option_mark"
            v-for="(item, index) in list.children"
            :key="index"
          >
            <i class="optionIcon">{{ item.option_mark }}</i>
            <div class="optionContent">{{item.option_content}}<img :src="item.option_img" v-if="item.option_img"  @click="checkImg(item.option_img)"/></div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="optionContentdiv1 lineH40 fontSize16">
        我的作答：<span :class="list.radio.length == 0 ? 'on' : ''">{{
          list.radio.length == 0 ? "未作答" : list.radio.toString()
        }}</span>
      </div>
    </div>
    <imgDialog ref="ImgDialog"></imgDialog>
  </div>
</template>

<script>
  import imgDialog from '@p/user/components/com/imgDialog.vue'
export default {
  components:{imgDialog},
  props: {
    list: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    checkImg(data) {
      this.$refs.ImgDialog.openInit(data);
    },},
};
</script>

<style scoped lang="scss">
@import "../assets/questionnaire";
.optionIcon {
  border-radius: 6px !important;
}
</style>

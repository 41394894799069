<template>
  <!-- 论述 -->
  <div class="discussWrap" :id="'question' + list.seq">
    <div class="detailTitle clearfix">
      <span class="detailMate">{{ list.seq }}</span>
      <div class="detailTitlefr">
        <span class="detailCate">开放式</span>
        <span class="detailScore"
          >[{{ list.required == 0 ? "选填" : "必填" }}]</span
        >
        {{ list.name }}<img :src="list.img" v-if="list.img" @click="checkImg(list.img)"/>
      </div>
    </div>
    <div class="itemDetail">
      <div class="detailOption">
        <!--        <div class="optionWrap">-->
        <!--          <el-input-->
        <!--            type="textarea"-->
        <!--            :rows="5"-->
        <!--            placeholder="请输入内容"-->
        <!--            v-model="list.radio"-->
        <!--            class="mb20"-->
        <!--            :disabled="true"-->
        <!--          ></el-input>-->
        <!--        </div>-->
        <div class="optionWrap1">
          <span class="fontSize14 lineH26">我的作答</span>
          <p class="lineH32 fontSize16" :class="list.radio != '' ? '' : 'on'">
            {{ list.radio != "" ? list.radio : "未作答" }}
          </p>
        </div>
      </div>
    </div>
    <imgDialog ref="ImgDialog"></imgDialog>
  </div>
</template>
<script>
  import imgDialog from '@p/user/components/com/imgDialog.vue'
export default {
  components:{imgDialog},
  props: {
    list: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: { checkImg(data) {
      this.$refs.ImgDialog.openInit(data);
    },},
};
</script>

<style scoped lang="scss">
@import "../assets/questionnaire";
</style>

<template>
  <div class="Number" :id="'question' + list.seq">
    <div class="detailTitle clearfix">
      <span class="detailMate">{{ list.seq }}</span>
      <div class="detailTitlefr">
        <span class="detailCate">数值型</span>
        <span class="detailScore"
          >[{{ list.required == 0 ? "选填" : "必填" }}]</span
        >
        {{ list.name }}<img :src="list.img"/>
      </div>
    </div>
    <div class="Numberslider">
      <div class="Numbersliderfl">
        <h5 class="fontSize20 lineH32">{{ list.min_val }}</h5>
        <p class="fontSize14 lineH32">{{list.min_val_brief}}</p>
      </div>
      <el-slider
        v-model="list.radio"
        :min="list.min_val"
        :max="list.max_val"
        :disabled="true"
      ></el-slider>
      <div class="Numbersliderfr">
        <h5 class="fontSize20 lineH32">{{ list.max_val }}</h5>
        <p class="fontSize14 lineH32">{{list.max_val_brief}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Number",
  props: {
    list: Object,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "../assets/questionnaire";
//问卷作答
/deep/.Numberslider {
  .el-slider__button {
    background: url("../assets/images/offcn_yxbbg45.png") no-repeat left top;
    width: 58px;
    height: 58px;
    border: none;
  }

  .el-slider__bar {
    height: 8px;
    border-radius: 4px;
  }
  .el-slider__runway.disabled .el-slider__bar {
    background-color: #409eff;
  }
  .el-slider__button-wrapper {
    width: 58px;
    height: 58px;
    top: -26px;
  }
}
/*/deep/.el-slider__button.dragging,/deep/.el-slider__button.hover,/deep/.el-slider__button:hover {*/
/*  background: url("../assets/images/offcn_yxbbg45_hover.png") no-repeat left top !important;*/
/*}*/
</style>

<template>
  <!-- 单选 -->
  <div class="radioWrap" :id="'question' + list.seq">
    <div class="detailTitle clearfix">
      <span class="detailMate">{{ list.seq }}</span>
      <div class="detailTitlefr">
        <span class="detailCate">单选题</span>
        <span class="detailScore"
          >[{{ list.required == 0 ? "选填" : "必填" }}]</span
        >
        {{ list.name }}<img :src="list.img" v-if="list.img" @click="checkImg(list.img)"/>
      </div>
    </div>
    <div class="itemDetail">
      <div class="detailOption">
        <el-radio-group v-model="list.radio" disabled>
          <el-radio
            :label="item.option_mark"
            v-for="(item, index) in list.children"
            :key="index"
          >
            <i class="optionIcon">{{ item.option_mark }}</i>
            <div class="optionContent">
              <div>{{item.option_content}}<img :src="item.option_img" v-if="item.option_img"  @click="checkImg(item.option_img)"/></div>
              <el-input
                v-model="list.radiocontent"
                placeholder="请输入内容"
                v-if="item.is_input == 1 && list.radio == item.option_mark"
                disabled
              ></el-input>
            </div>
          </el-radio>
        </el-radio-group>
        <div class="optionContentdiv1 lineH40 fontSize16">
          我的作答：<span :class="list.radio == '' ? 'on' : ''">{{
            list.radio == "" ? "未作答" : list.radio
          }}</span>
        </div>
      </div>
      <imgDialog ref="ImgDialog"></imgDialog>
    </div>
  </div>
</template>

<script>
  import imgDialog from '@p/user/components/com/imgDialog.vue'
export default {
  components:{imgDialog},
  props: {
    list: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    checkImg(data) {
      this.$refs.ImgDialog.openInit(data);
    },

  },
};
</script>

<style scoped lang="scss">
@import "../assets/questionnaire";
</style>

<template>
  <!-- 问卷作答 -->
  <div class="questionsWrap1">
    <header>
      <div class="headerWrap">
        <div class="headerTitle">
          <i class="el-icon-arrow-left" @click="goReturn()"></i>
          {{ questionData.name }}
        </div>
        <a class="fontSize16 lineH32 headersubmit">
          <i class="iconfont icon-yxw"></i> 已提交
        </a>
      </div>
    </header>
    <main>
      <div class="mainWrap" id="mainWrap">
        <div v-for="(item, index) in question_infos" :key="index">
          <!-- 单选 -->
          <Radio
            class="itemWrap"
            v-if="item.question_type == 1"
            :list="item"
          ></Radio>
          <!-- 多选 -->
          <Checkbox
            class="itemWrap"
            v-if="item.question_type == 2"
            :list="item"
          ></Checkbox>
          <!-- 开放式 -->
          <Discuss
            class="itemWrap"
            v-if="item.question_type == 3"
            :list="item"
          ></Discuss>
          <!-- 数值型-->
          <Number v-if="item.question_type == 4" :list="item" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { info } from "./assets/api";
import Radio from "./componentsreport/Radio"; // 单选
import Checkbox from "./componentsreport/Checkbox"; // 多选
import Discuss from "./componentsreport/Discuss"; // 论述
import Number from "./componentsreport/Number"; //数值型
export default {
  name: "course_questionnaire",
  components: {
    Radio,
    Checkbox,
    Discuss,
    Number,
  },
  data() {
    return {
      question_infos: [],
      questionData: {},
      num: "",
      seconds: 0,
      survey_id: "",
      course_id: "",
      answer_id: "",
      lesson_id:'',
    };
  },
  mounted() {
    if (
      this.$route.query.survey_id != undefined &&
      this.$route.query.source_id != ""
    ) {
      this.survey_id = this.$route.query.survey_id;
    }
    if (
      this.$route.query.course_id != undefined &&
      this.$route.query.course_id != ""
    ) {
      this.course_id = this.$route.query.course_id;
    }
    if (
      this.$route.query.answer_id != undefined &&
      this.$route.query.answer_id != ""
    ) {
      this.answer_id = this.$route.query.answer_id;
    }
    if (
            this.$route.query.lesson_id != undefined &&
            this.$route.query.lesson_id != ""
    ) {
      this.lesson_id = this.$route.query.lesson_id;
    }
    this.info();
  },
  methods: {
    goReturn(){
      this.$router.go(-2)
    },
    //数据处理
    dataProcessing(arr) {
      arr.map((item) => {
        if (item.question_type == 1) {
          this.$set(item, "radio", item.answer_option);
          this.$set(item, "radiocontent", item.answer_content);
        } else if (item.question_type == 2) {
          let question_type2arr = item.answer_option.split(",");
          this.$set(item, "radio", question_type2arr);
        } else if (item.question_type == 4) {
          this.$set(item, "radio", parseInt(item.answer_content));
        } else if (item.question_type == 3) {
          this.$set(item, "radio", item.answer_content);
        }
      });
      this.question_infos = arr;
    },
    info() {
      let params = {
        survey_id: this.survey_id,
        course_id: this.course_id,
        answer_id: this.answer_id,
        lesson_id: this.lesson_id
      };
      info(params).then((res) => {
        if (res.code == 0) {
          this.dataProcessing(res.data.questions);
          this.questionData = res.data.survey;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./assets/questionnaire";
</style>
